import React from 'react';
import { ExploreOurOfferSection } from 'sections/Home/ExploreOurOfferSection';
import { Hero } from 'sections/Home/Hero/Hero';
import { HowWeWorkSection } from 'sections/Home/HowWeWorkSection';
import { OurImpactSection } from 'sections/Home/OurImpactSection';
import { TakeThisStepSection } from 'sections/_universal/TakeThisStepSection';
import { QuoteSection } from 'sections/Home/QuoteSection';
import { TestimonialsSection } from 'sections/Home/TestimonialsSection';
import { TrustedBySection } from 'sections/_universal/TrustedBySection';
import SEO from 'components/_layout/Seo';
import { GPTSection } from 'sections/Home/GPTSection';

export default () => {
  return (
    <>
      <SEO
        title="Home"
        meta={[
          {
            name: 'keywords',
            content:
              'codahead, web development company, web app development company, web application development company, web development services company',
          },
        ]}
        description={
          "Discover Codahead – a leading software and custom web development company delivering tailored tech solutions. Let's create your next digital success!\n"
        }
      />
      <Hero />
      <QuoteSection />
      <TrustedBySection />
      <ExploreOurOfferSection />
      <GPTSection />
      <OurImpactSection />
      <HowWeWorkSection />
      <TestimonialsSection />
      <TakeThisStepSection />
    </>
  );
};
